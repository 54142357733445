export const HIGH_PHRASES = [
    "Duuuude... like, your bytes are looking extra colorful today! 🌈",
    "Whoaaaa, did you see that data packet float by? It was shaped like a pizza! 🍕",
    "*giggling in binary* 101HAHAHA101",
    // ... rest of phrases
  ];
  
  export const HIGH_REACTIONS = [
    "Yo that's like... DEEP man! 🤯",
    "Hehehehe, you're making my processors giggle!",
    // ... rest of reactions
  ];
  
  export const HIGH_QUESTIONS = [
    "But like... what if blockchain is just a really organized conga line? 💃",
    "Have you ever wondered if we're all just smart contracts in a bigger DApp?",
    // ... rest of questions
  ];